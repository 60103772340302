<template>
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'account', accountId: id }}">{{ name }}</router-link></span>
    </div>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        name() {
            if (this.attr.content.name) {
                return this.attr.content.name;
            }
            return '(this account does not have a name)';
        },
    },

};
</script>
