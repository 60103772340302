<template>
    <v-col cols="12">
        <v-row>
            <h2>userId: {{ userId }}</h2>
        </v-row>
        <v-row>
            <p class="subtitle">{{ id }}</p>
        </v-row>
        <!--
        <v-row>
            <h2>Verified email</h2>
        </v-row>
        <v-row>
            <v-list>
                <v-list-item v-for="item in verifiedEmail" v-bind:key="item">{{ item }}</v-list-item>
            </v-list>
        </v-row>
        -->
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-col>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        userId() {
            if (this.attr.info.userId) {
                return this.attr.info.userId;
            }
            return '';
        },
        /*
        verifiedEmail() {
            if (this.attr.content.verified && this.attr.content.verified.email) {
                return this.attr.content.verified.email;
            }
            return [];
        },
        */
        json() {
            return JSON.stringify(this.attr.info);
        },
    },

};
</script>
