<template>
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'client', clientId: id }}">{{ system }} on {{ device }}</router-link></span>
        <span class="ml-2" v-if="isNotificationEnabled">Notifications enabled</span>
        <p class="caption">{{ id }}</p>
    </div>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        system() {
            if (this.attr.info && this.attr.info.platform) {
                return `${this.attr.info.platform.systemName} ${this.attr.info.platform.systemVersion}`;
            }
            return '(platform unknown)';
        },
        device() {
            if (this.attr.info && this.attr.info.platform) {
                return `${this.attr.info.platform.makerName} ${this.attr.info.platform.modelName}`;
            }
            return '(device unknown)';
        },
        isNotificationEnabled() {
            if (this.attr.info && this.attr.info.notification) {
                return this.attr.info.notification.service && this.attr.info.notification.clientId;
            }
            return false;
        },
        /*
        name() {
            if (this.attr.content.name) {
                return this.attr.content.name;
            }
            return '(this user does not have a name)';
        },
        */
    },

};
</script>
