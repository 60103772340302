<template>
    <v-col cols="12">
        <v-row>
            <h2>{{ tableName }}</h2>
        </v-row>
        <v-row>
            <p class="subtitle">{{ schemaName }}</p>
        </v-row>
        <v-row>
            <h2>Columns</h2>
        </v-row>
        <v-row>
            <v-list>
                <v-list-item v-for="item in columnList" v-bind:key="item.column_name">{{ item.column_name }}</v-list-item>
            </v-list>
        </v-row>
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
    <!--
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        -->
    </v-col>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        tableName() {
            if (this.attr.tableName) {
                return this.attr.tableName;
            }
            return '';
        },
        schemaName() {
            if (this.attr.schemaName) {
                return this.attr.schemaName;
            }
            return '';
        },
        columnList() {
            if (this.attr.columnList) {
                return this.attr.columnList;
            }
            return [];
        },
        /*
        json() {
            return JSON.stringify(this.attr.content);
        },
        */
    },

};
</script>
