<template>
    <div>
        <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-row>
            <h2>Name: {{ name }} ({{ accountType }})</h2>
        </v-row>
        <v-row>
            <p class="subtitle">{{ id }}</p>
        </v-row>
        <!--
        <v-row>
            <h2>Verified email</h2>
        </v-row>
        <v-row>
            <v-list>
                <v-list-item v-for="item in verifiedEmail" v-bind:key="item">{{ item }}</v-list-item>
            </v-list>
        </v-row>
        -->
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-row>
            <v-list dense>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: { id:'realm-list-by-accountid', accountId: id } }">Realms</router-link>
                    <span class="ml-2" v-if="realmCount !== null">({{ realmCount }})</span>
                </v-list-item>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: { id:'account-user-list-by-accountid', accountId: id } }">Account users</router-link>
                    <span class="ml-2" v-if="accountUserCount !== null">({{ accountUserCount }})</span>
                </v-list-item>
            </v-list>
        </v-row>
    </v-col>
        </v-row>
        <v-row>
            <v-checkbox v-model="isCurrent">
                <template v-slot:label>Current</template>
            </v-checkbox>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider class="mx-5 mb-10"></v-divider>
        <v-row>
            <v-dialog
                v-model="isDeleteDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" outlined>Delete</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Delete Account
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is not reversible.
                    </v-card-text>
                    <v-card-text>
                    New accounts are assigned a randomly generated identifier and all account users would need to be linked again with the new account.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="deleteAccount">Delete</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
        </v-row>
</div>
</template>

<script>
export default {
    data() {
        return {
            isDeleteDialogVisible: false,
            realmCount: null,
            accountUserCount: null,
        };
    },

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        name() {
            if (this.attr.content.name) {
                return this.attr.content.name;
            }
            return '';
        },
        accountType() {
            if (this.attr.content.type) {
                return this.attr.content.type;
            }
            return '';
        },
        isCurrent: {
            get() {
                if (typeof this.attr.content.isCurrent === 'boolean') {
                    return this.attr.content.isCurrent;
                }
                return false;
            },
            set(value) {
                console.log(`isCurrent.set called with value ${value}`);
                this.editIsCurrent(value);
            },
        },
        /*
        verifiedEmail() {
            if (this.attr.content.verified && this.attr.content.verified.email) {
                return this.attr.content.verified.email;
            }
            return [];
        },
        */
        json() {
            return JSON.stringify(this.attr.content);
        },
    },

    methods: {
        async deleteAccount() {
            const { isDeleted } = await this.$client.data.deleteItemById({ type: 'account', id: this.attr.id });
            this.isDeleteDialogVisible = false;
            if (isDeleted) {
                this.$router.go(-1); // return to last page, since there's no content to show here now
            } else {
                // TODO: show snackbar with error message
            }
        },
        async editIsCurrent(value) {
            const { isEdited } = await this.$client.account.edit(this.attr.id, { isCurrent: value });
            console.log(`editIsCurrent: isEdited ${isEdited}`);
        },
    },

    async mounted() {
        // get count of realms and account users
        const { content: { realmCount, accountUserCount } } = await this.$client.data.report({ id: 'account-related-count-by-accountId', accountId: this.attr.id });
        this.realmCount = realmCount;
        this.accountUserCount = accountUserCount;
    },
};
</script>
