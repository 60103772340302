<template>
    <div>
        <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-row>
            <h2>Email: {{ email }}</h2>
        </v-row>
        <v-row>
            <p class="subtitle">{{ id }}</p>
        </v-row>
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-row>
            <v-list dense>
                <v-list-item v-if="userId">
                    <router-link :to="{ path:'/report/view', query: {id:'user', userId } }">User</router-link>
                    <!-- TODO: check for broken link, instead of count, so we can display that the user id doesn't exist, instead of displaying a link to the non-existent user -->
                </v-list-item>
                <!--
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: {id:'user-publickey-list-by-userid', userId: id } }">Public keys</router-link>
                    <span class="ml-2" v-if="userPublicKeyCount !== null">({{ userPublicKeyCount }})</span>
                </v-list-item>
                -->
            </v-list>
        </v-row>
    </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider class="mx-5 mb-10"></v-divider>
        <v-row>
            <v-dialog
                v-model="isDeleteDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" outlined>Delete</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Delete User Email
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is not reversible.
                    </v-card-text>
                    <!-- TODO: client keys, key dist
                    <v-card-text v-if="accountCount">
                    The user will be removed from {{ accountCount }} linked accounts.
                    </v-card-text>
                    <v-card-text v-if="userPublicKeyCount">
                    {{ userPublicKeyCount }} linked public keys will be deleted.
                    </v-card-text>
                    -->

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="deleteUserEmail">Delete</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDeleteDialogVisible: false,
        };
    },

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        userId() {
            if (this.attr.info) {
                return this.attr.info.userId;
            }
            return '';
        },
        email() {
            return this.attr.email;
        },
        json() {
            return JSON.stringify(this.attr.info);
        },
    },

    methods: {
        async deleteUserEmail() {
            const { isDeleted } = await this.$client.data.deleteItemById({ type: 'user-email', id: this.attr.id });
            this.isDeleteDialogVisible = false;
            if (isDeleted) {
                this.$router.go(-1); // return to last page, since there's no content to show here now
            } else {
                // TODO: show snackbar with error message
            }
        },
    },

    async mounted() {
        // get count of realms and account users
        // const { content: { accountCount, userPublicKeyCount } } = await this.$client.data.report({ id: 'user-related-count-by-userId', userId: this.attr.id });
        // this.accountCount = accountCount;
        // this.userPublicKeyCount = userPublicKeyCount;
    },
};
</script>
