<template>
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'realm', realmId: realmId }}">{{ label }} ({{ domain }})</router-link></span>
    </div>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        realmId() {
            if (this.attr.realm_id) {
                return this.attr.realm_id;
            }
            return '';
        },
        label() {
            if (this.attr.content.label) {
                return this.attr.content.label;
            }
            return '';
        },
        domain() {
            if (this.attr.content.domain) {
                return this.attr.content.domain;
            }
            return '';
        },
    },

};
</script>
