<template>
    <v-container>
            <v-row justify="center" class="py-5">
                <h1 class="display-1 font-weight-light">Edit data</h1>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="12" md="12" lg="10" xl="10" class="pa-0">
                    <v-card tile elevation="4" class="pb-5">
                        <v-toolbar dense flat color="blue lighten-4">
                            <v-toolbar-title>{{label}}</v-toolbar-title>
                            <v-spacer/>
                            <template v-if="isEditable && isModeView">
                                <v-btn icon @click="switchToEditMode"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></v-btn>
                            </template>
                            <template v-if="isEditable && isModeEdit">
                                <v-btn icon @click="switchToViewMode"><font-awesome-icon :icon="['fas', 'times']" /></v-btn>
                            </template>
                        </v-toolbar>
                        <v-card-text class="mx-0 px-4">
                        <!-- <v-card-text class="title text-center"></v-card-text> -->
                        <!-- <v-divider class="mx-5"></v-divider> -->
                        <!-- <v-card-text v-if="json">{{json}}</v-card-text> -->
                        <div v-if="isViewAvailable && isModeView">
                            <component :is="viewComponentName" v-if="viewComponentName" :attr="content"></component>
                        </div>
                        <div v-if="isEditAvailable && isModeEdit">
                            <component :is="editComponentName" v-if="editComponentName" :attr="content" @refresh="refresh"></component>
                        </div>
                        <v-list v-if="isListAvailable">
                            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2">
                                <!-- <span>{{ JSON.stringify(item) }}</span> -->
                                <component :is="listItemComponentName" v-if="listItemComponentName" :attr="item"></component>
                            </v-list-item>
                        </v-list>
                        <p v-if="isListEmpty" class="body-1 text-center">No records.</p>
                        <p class="body-1 red--text" v-if="error" align="center">{{ error }}</p>
                        </v-card-text>
                        <div v-if="link.length > 0">
                            <v-divider class="mx-5"></v-divider>
                            <v-list>
                                <v-list-item v-for="item in link" v-bind:key="item.label" class="my-2">
                                    <!-- <span>{{ JSON.stringify(item) }}</span> -->
                                    <router-link :to="{path: item.path, query: item.query}">{{item.label}}</router-link>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
    </v-container>
</template>
<script>
import TableListItem from '@/components/TableListItem.vue';
import TableViewItem from '@/components/TableViewItem.vue';
import ClientListItem from '@/components/ClientListItem.vue';
import ClientViewItem from '@/components/ClientViewItem.vue';
import UserEmailListItem from '@/components/UserEmailListItem.vue';
import UserEmailViewItem from '@/components/UserEmailViewItem.vue';
import UserListItem from '@/components/UserListItem.vue';
import UserViewItem from '@/components/UserViewItem.vue';
import UserEditItem from '@/components/UserEditItem.vue';
import AccountListItem from '@/components/AccountListItem.vue';
import AccountViewItem from '@/components/AccountViewItem.vue';
import RealmListItem from '@/components/RealmListItem.vue';
import RealmViewItem from '@/components/RealmViewItem.vue';
import SessionListItem from '@/components/SessionListItem.vue';
import SessionViewItem from '@/components/SessionViewItem.vue';
import UserPublicKeyListItem from '@/components/UserPublicKeyListItem.vue';
import UserPublicKeyViewItem from '@/components/UserPublicKeyViewItem.vue';
import LinkAccountUserListItem from '@/components/LinkAccountUserListItem.vue';
import LinkAccountUserViewItem from '@/components/LinkAccountUserViewItem.vue';
import LinkRealmUserListItem from '@/components/LinkRealmUserListItem.vue';
import LinkRealmUserViewItem from '@/components/LinkRealmUserViewItem.vue';

// import { /* mapState, */ mapGetters } from 'vuex';

export default {
    components: {
        TableListItem,
        TableViewItem,
        ClientListItem,
        ClientViewItem,
        UserListItem,
        UserViewItem,
        UserEditItem,
        UserEmailListItem,
        UserEmailViewItem,
        AccountListItem,
        AccountViewItem,
        RealmListItem,
        RealmViewItem,
        SessionListItem,
        SessionViewItem,
        UserPublicKeyListItem,
        UserPublicKeyViewItem,
        LinkAccountUserListItem,
        LinkAccountUserViewItem,
        LinkRealmUserListItem,
        LinkRealmUserViewItem,
    },
    data() {
        return {
            label: null,
            json: null,
            list: [],
            listItemComponentName: null,
            content: null, // only for view
            viewComponentName: null,
            link: [], // list of related links; each item has { label, path, query? } where optional query is a map with query parameters
            error: null,
            isEditable: false,
            editComponentName: null,
            mode: 'view',
        };
    },
    computed: {
        isListAvailable() {
            return this.list && this.list.length > 0 && this.listItemComponentName;
        },
        isListEmpty() {
            return this.list && this.list.length === 0 && this.listItemComponentName;
        },
        isViewAvailable() {
            return this.content && this.viewComponentName;
        },
        isEditAvailable() {
            return this.content && this.editComponentName;
        },
        isModeView() {
            return this.mode === 'view';
        },
        isModeEdit() {
            return this.mode === 'edit';
        },
    },
    watch: {
        $route: function $routeWatcher(to /* to, from */) {
            console.log(`new route query : ${JSON.stringify(to.query)}`);
            this.reset();
        },
    },
    methods: {
        async reportAllTables() {
            const report = await this.$client.data.report({ id: 'all-tables' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'all-tables'; // report id
            }
            this.listItemComponentName = 'TableListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportTableByName({ tableName }) {
            console.log(`reportTableByName: tableName ${tableName}`);
            const report = await this.$client.data.report({ id: 'tableByName', tableName });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'table'; // report id
            }
            this.viewComponentName = 'TableViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportAllClients() {
            const report = await this.$client.data.report({ id: 'client-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'client-list'; // report id
            }
            this.listItemComponentName = 'ClientListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportClientListByUserId({ userId }) {
            const report = await this.$client.data.report({ id: 'client-list-by-userid', userId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'client-list-by-userid'; // report id
            }
            this.listItemComponentName = 'ClientListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportAllUsers() {
            const report = await this.$client.data.report({ id: 'all-users' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'all-users'; // report id
            }
            this.listItemComponentName = 'UserListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportAccountUserListByAccountId({ accountId }) {
            const report = await this.$client.data.report({ id: 'account-user-list-by-accountid', accountId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'account-user-list-by-accountid'; // report id
            }
            this.listItemComponentName = 'LinkAccountUserListItem'; // to show and edit the relationship itself; previously we linked directly to 'UserListItem' but then we miss permissions editing;
            // this.json = JSON.stringify(report);
            this.list = report.data;
            this.link.push({
                label: 'Link a user to this account',
                path: '/account/link-user',
                query: { accountId },
            });
        },
        async reportUserById({ userId }) {
            console.log(`reportUserById: userId ${userId}`);
            const report = await this.$client.data.report({ id: 'userById', userId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user'; // report id
            }
            this.viewComponentName = 'UserViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
            this.isEditable = true;
            this.editComponentName = 'UserEditItem';
        },
        async reportClientById({ clientId }) {
            console.log(`reportClientById: clientId ${clientId}`);
            const report = await this.$client.data.report({ id: 'client', clientId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'client'; // report id
            }
            this.viewComponentName = 'ClientViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportUserPublicKeyWithNonexistentUserList() {
            const report = await this.$client.data.report({ id: 'user-publickey-with-nonexistent-user-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-publickey-with-nonexistent-user-list'; // report id
            }
            this.listItemComponentName = 'UserPublicKeyListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportUserPublicKeyList() {
            const report = await this.$client.data.report({ id: 'user-publickey-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-publickey-list'; // report id
            }
            this.listItemComponentName = 'UserPublicKeyListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportUserPublicKeyListByUserId({ userId }) {
            const report = await this.$client.data.report({ id: 'user-publickey-list-by-userid', userId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-publickey-list-by-userid'; // report id
            }
            this.listItemComponentName = 'UserPublicKeyListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportUserPublicKeyById({ userPublicKeyId }) {
            console.log(`reportUserPublicKeyById: userPublicKeyId ${userPublicKeyId}`);
            const report = await this.$client.data.report({ id: 'userPublicKeyById', userPublicKeyId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-publickey'; // report id
            }
            this.viewComponentName = 'UserPublicKeyViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportUserEmailList() {
            const report = await this.$client.data.report({ id: 'user-email-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-email-list'; // report id
            }
            this.listItemComponentName = 'UserEmailListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportUserEmailListByUserId({ userId }) {
            const report = await this.$client.data.report({ id: 'user-email-list-by-userid', userId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-email-list-by-userid'; // report id
            }
            this.listItemComponentName = 'UserEmailListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportUserEmailById({ userEmailId }) {
            console.log(`reportUserEmailById: userEmailId ${userEmailId}`);
            const report = await this.$client.data.report({ id: 'userEmailById', userEmailId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-email'; // report id
            }
            this.viewComponentName = 'UserEmailViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportAllAccounts() {
            const report = await this.$client.data.report({ id: 'all-accounts' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'all-accounts'; // report id
            }
            this.listItemComponentName = 'AccountListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportAccountTypeEnterpriseWithoutRealmList() {
            const report = await this.$client.data.report({ id: 'account-type-enterprise-without-realm-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'account-type-enterprise-without-realm-list'; // report id
            }
            this.listItemComponentName = 'AccountListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportLinkRealmUserWithoutUserList() {
            const report = await this.$client.data.report({ id: 'link-realm-user-with-nonexistent-user-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'link-realm-user-with-nonexistent-user-list'; // report id
            }
            this.listItemComponentName = 'LinkRealmUserListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async listClientWithEmptyUser() {
            const report = await this.$client.data.report({ id: 'client-with-empty-user-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'client-with-empty-user-list'; // report id
            }
            this.listItemComponentName = 'ClientListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async listClientWithNonexistentUser() {
            const report = await this.$client.data.report({ id: 'client-with-nonexistent-user-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'client-with-nonexistent-user-list'; // report id
            }
            this.listItemComponentName = 'ClientListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async listUserEmailWithNonexistentUser() {
            const report = await this.$client.data.report({ id: 'user-email-with-nonexistent-user-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'user-email-with-nonexistent-user-list'; // report id
            }
            this.listItemComponentName = 'UserEmailListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportLinkRealmUserListByRealmId({ realmId }) {
            const report = await this.$client.data.report({ id: 'link-realm-user-list-by-realmid', realmId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'link-realm-user-list-by-realmid'; // report id
            }
            this.listItemComponentName = 'LinkRealmUserListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportAccountJunkList() {
            const report = await this.$client.data.report({ id: 'account-junk-list' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'account-junk-list'; // report id
            }
            this.listItemComponentName = 'AccountListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportAccountListByUserId({ userId }) {
            const report = await this.$client.data.report({ id: 'account-list-by-userid', userId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'account-list-by-userid'; // report id
            }
            this.listItemComponentName = 'AccountListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportAccountById({ accountId }) {
            console.log(`reportAccountById: accountId ${accountId}`);
            const report = await this.$client.data.report({ id: 'accountById', accountId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'account'; // report id
            }
            this.viewComponentName = 'AccountViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportAllRealms() {
            const report = await this.$client.data.report({ id: 'all-realms' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'all-realms'; // report id
            }
            this.listItemComponentName = 'RealmListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportRealmListByAccountId({ accountId }) {
            const report = await this.$client.data.report({ id: 'realm-list-by-accountid', accountId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'realm-list-by-accountid'; // report id
            }
            this.listItemComponentName = 'RealmListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportRealmByRealmId({ realmId }) {
            console.log(`reportRealmByRealmId: realmId ${realmId}`);
            const report = await this.$client.data.report({ id: 'realmByRealmId', realmId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'realm'; // report id
            }
            this.viewComponentName = 'RealmViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportLinkRealmUserById({ linkRealmUserId }) {
            console.log(`reportLinkRealmUserById: linkRealmUserId ${linkRealmUserId}`);
            const report = await this.$client.data.report({ id: 'link-realm-user', linkRealmUserId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'link-realm-user'; // report id
            }
            this.viewComponentName = 'LinkRealmUserViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportLinkAccountUserById({ linkAccountUserId }) {
            console.log(`reportLinkAccountUserById: linkAccountUserId ${linkAccountUserId}`);
            const report = await this.$client.data.report({ id: 'link-account-user', linkAccountUserId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'link-account-user'; // report id
            }
            this.viewComponentName = 'LinkAccountUserViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        async reportAllSessions() {
            const report = await this.$client.data.report({ id: 'all-sessions' });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'all-sessions'; // report id
            }
            this.listItemComponentName = 'SessionListItem';
            // this.json = JSON.stringify(report);
            this.list = report.data;
        },
        async reportSessionById({ sessionId }) {
            console.log(`reportSessionById: sessionId ${sessionId}`);
            const report = await this.$client.data.report({ id: 'sessionById', sessionId });
            if (!report) {
                this.error = 'Request failed';
                return;
            }
            if (report.label) {
                this.label = report.label;
            } else {
                this.label = 'session'; // report id
            }
            this.viewComponentName = 'SessionViewItem';
            // this.json = JSON.stringify(report);
            // this.list = report.data;
            this.content = report.content;
        },
        reset() {
            this.label = null;
            this.json = null;
            this.list = [];
            this.listItemComponentName = null;
            this.content = null;
            this.viewComponentName = null;
            this.link = [];
            this.error = null;
            this.isEditable = false;
            this.mode = 'view';
            this.refresh();
        },
        refresh() {
            console.log(`reset: route query: ${JSON.stringify(this.$route.query)}`);
            console.log(`reset: route userId: ${JSON.stringify(this.$route.query.userId)}`);
            const { id } = this.$route.query;
            console.log(`report id: ${id}`);
            switch (id) {
            case 'all-tables':
                this.reportAllTables();
                break;
            case 'tableByName':
                this.reportTableByName({ tableName: this.$route.query.tableName });
                break;
            case 'all-clients':
                this.reportAllClients();
                break;
            case 'client-list-by-userid':
                this.reportClientListByUserId({ userId: this.$route.query.userId });
                break;
            case 'client':
                this.reportClientById({ clientId: this.$route.query.clientId });
                break;
            case 'all-users':
                this.reportAllUsers();
                break;
            case 'account-user-list-by-accountid':
                this.reportAccountUserListByAccountId({ accountId: this.$route.query.accountId });
                break;
            case 'link-account-user':
                this.reportLinkAccountUserById({ linkAccountUserId: this.$route.query.linkAccountUserId });
                break;
            case 'user':
                this.reportUserById({ userId: this.$route.query.userId });
                break;
            case 'all-accounts':
                this.reportAllAccounts();
                break;
            case 'account-type-enterprise-without-realm-list':
                this.reportAccountTypeEnterpriseWithoutRealmList();
                break;
            case 'account-junk-list':
                this.reportAccountJunkList();
                break;
            case 'account-list-by-userid':
                this.reportAccountListByUserId({ userId: this.$route.query.userId });
                break;
            case 'account':
                this.reportAccountById({ accountId: this.$route.query.accountId });
                break;
            case 'all-realms':
                this.reportAllRealms();
                break;
            case 'realm-list-by-accountid':
                this.reportRealmListByAccountId({ accountId: this.$route.query.accountId });
                break;
            case 'realm':
                this.reportRealmByRealmId({ realmId: this.$route.query.realmId });
                break;
            case 'all-sessions':
                this.reportAllSessions();
                break;
            case 'session':
                this.reportSessionById({ sessionId: this.$route.query.sessionId });
                break;
            case 'user-publickey-list':
                this.reportUserPublicKeyList();
                break;
            case 'user-publickey-with-nonexistent-user-list':
                this.reportUserPublicKeyWithNonexistentUserList();
                break;
            case 'user-publickey-list-by-userid':
                this.reportUserPublicKeyListByUserId({ userId: this.$route.query.userId });
                break;
            case 'user-publickey':
                this.reportUserPublicKeyById({ userPublicKeyId: this.$route.query.userPublicKeyId });
                break;
            case 'user-email-list':
                this.reportUserEmailList();
                break;
            case 'user-email-list-by-userid':
                this.reportUserEmailListByUserId({ userId: this.$route.query.userId });
                break;
            case 'user-email':
                this.reportUserEmailById({ userEmailId: this.$route.query.userEmailId });
                break;
            case 'link-realm-user':
                this.reportLinkRealmUserById({ linkRealmUserId: this.$route.query.linkRealmUserId });
                break;
            case 'link-realm-user-with-nonexistent-user-list':
                this.reportLinkRealmUserWithoutUserList();
                break;
            case 'link-realm-user-list-by-realmid':
                this.reportLinkRealmUserListByRealmId({ realmId: this.$route.query.realmId });
                break;
            case 'client-with-empty-user-list':
                this.listClientWithEmptyUser();
                break;
            case 'client-with-nonexistent-user-list':
                this.listClientWithNonexistentUser();
                break;
            case 'user-email-with-nonexistent-user-list':
                this.listUserEmailWithNonexistentUser();
                break;
            default:
                this.label = 'Unknown';
                this.json = null;
            }
        },
        switchToEditMode() {
            if (this.editComponentName) {
                console.log(`switching to edit mode ${this.editComponentName}`);
                this.mode = 'edit';
            } else {
                // TODO: snackbar with error message
                console.error('no edit component');
            }
        },
        switchToViewMode() {
            if (this.viewComponentName) {
                console.log(`switching to view mode ${this.viewComponentName}`);
                this.mode = 'view';
            } else {
                // TODO: snackbar with error message
                console.error('no view component');
            }
        },
    },
    created() {
        this.reset();
    },
};
</script>
