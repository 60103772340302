<template>
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user-email', userEmailId: id }}">{{ email }}</router-link></span>
        <p class="caption">{{ id }}</p>
    </div>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        email() {
            return this.attr.email;
        },
    },

};
</script>
