<template>
    <div>
        <!-- <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span> -->
        <span><router-link :to="{path: '/report/view', query: { id: 'link-account-user', linkAccountUserId: id }}">{{ name }}</router-link></span>
        <p class="caption">{{ id }} Permissions: {{ permit }}</p>
    </div>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.link_id;
        },
        name() {
            if (this.attr.user_content.name) {
                return this.attr.user_content.name;
            }
            return '(this user does not have a name)';
        },
        permit() {
            if (this.attr.link_content.permit && this.attr.link_content.permit.length > 0) {
                return JSON.stringify(this.attr.link_content.permit);
            }
            return '(no permissions)';
        },
    },

};
</script>
