<template>
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user-publickey', userPublicKeyId: id }}">{{ id }}</router-link></span>
        <p class="caption" v-if="linkOwnerUserId">userId: {{ linkOwnerUserId }}</p>
    </div>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        linkOwnerUserId() {
            if (this.attr.content.linkOwnerUserId) {
                return this.attr.content.linkOwnerUserId;
            }
            return '';
        },
    },

};
</script>
