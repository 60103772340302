<template>
    <div>
        <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-row>
            <h2>User Id: {{ userId }}</h2>
        </v-row>
        <v-row>
            <p class="subtitle">{{ id }}</p>
        </v-row>
        <v-row>
            <span class="ml-2" v-if="isNotificationEnabled">Notifications enabled</span>
        </v-row>
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-row>
            <v-list dense>
                <v-list-item v-if="userId">
                    <router-link :to="{ path:'/report/view', query: {id:'user', userId } }">User</router-link>
                    <!-- TODO: check for broken link, instead of count, so we can display that the user id doesn't exist, instead of displaying a link to the non-existent user -->
                </v-list-item>
                <!--
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: {id:'user-publickey-list-by-userid', userId: id } }">Public keys</router-link>
                    <span class="ml-2" v-if="userPublicKeyCount !== null">({{ userPublicKeyCount }})</span>
                </v-list-item>
                -->
            </v-list>
        </v-row>
    </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider class="mx-5 mb-10"></v-divider>
        <v-row>
            <v-dialog
                v-model="isDeleteDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" outlined>Delete</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Delete User
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is not reversible.
                    </v-card-text>
                    <!-- TODO: client keys, key dist
                    <v-card-text v-if="accountCount">
                    The user will be removed from {{ accountCount }} linked accounts.
                    </v-card-text>
                    <v-card-text v-if="userPublicKeyCount">
                    {{ userPublicKeyCount }} linked public keys will be deleted.
                    </v-card-text>
                    -->

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="deleteClient">Delete</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>

            <v-dialog
                v-model="isResetClientUserIdDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn class="ml-2" v-on="on" color="red" outlined>Reset User Id</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Reset Client User Id
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is not reversible.
                    </v-card-text>
                    <v-card-text>
                    The client will lose its association with the existing user information in the main website and all authentication realms.
                    </v-card-text>
                    <!-- TODO: client keys, key dist
                    <v-card-text v-if="accountCount">
                    The user will be removed from {{ accountCount }} linked accounts.
                    </v-card-text>
                    <v-card-text v-if="userPublicKeyCount">
                    {{ userPublicKeyCount }} linked public keys will be deleted.
                    </v-card-text>
                    -->

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="resetClientUserId">Reset User Id</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDeleteDialogVisible: false,
            isResetClientUserIdDialogVisible: false,
            // accountCount: null,
            // userPublicKeyCount: null,
        };
    },

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        userId() {
            if (this.attr.info) {
                return this.attr.info.userId;
            }
            return '';
        },
        isNotificationEnabled() {
            if (this.attr.info && this.attr.info.notification) {
                return this.attr.info.notification.service && this.attr.info.notification.clientId;
            }
            return false;
        },
        json() {
            return JSON.stringify(this.attr.info);
        },
    },

    methods: {
        async deleteClient() {
            // delete user public keys
            /*
            let error = false;

            if (this.userPublicKeyCount) {
                const { data } = await this.$client.data.report({ id: 'user-publickey-list-by-userid', userId: this.attr.id });
                const userPublicKeyIdList = data.map(item => item.id);
                console.log(`deleteClient: found ${data.length} related public keys: ${JSON.stringify(userPublicKeyIdList)}`);
                const userPublicKeyDeletePromiseList = userPublicKeyIdList.map(userPublicKeyId => client.data.deleteItemById({ type: 'user-publickey', id: userPublicKeyId }));
                const isDeletedList = await Promise.all(userPublicKeyDeletePromiseList);
                console.log(`deleteClient: delete results ${JSON.stringify(isDeletedList)}`);
                const isDeleteError = isDeletedList.reduce((prev, current) => prev || !current, false);
                console.log(`deleteClient: error: ${isDeleteError}`);
                error = error || isDeleteError;
            }

            if (this.accountCount) {
                const { data } = await this.$client.data.report({ id: 'account-list-by-userid', userId: this.attr.id });
                const linkAccountUserList = data.map(item => item.link_id);
                console.log(`deleteClient: found ${data.length} related account-user links: ${JSON.stringify(linkAccountUserList)}`);
                const linkAccountUserDeletePromiseList = linkAccountUserList.map(linkAccountUserId => client.data.deleteItemById({ type: 'link-account-user', id: linkAccountUserId }));
                const isDeletedList = await Promise.all(linkAccountUserDeletePromiseList);
                console.log(`deleteClient: delete results ${JSON.stringify(isDeletedList)}`);
                const isDeleteError = isDeletedList.reduce((prev, current) => prev || !current, false);
                console.log(`deleteClient: error: ${isDeleteError}`);
                error = error || isDeleteError;
            }

            if (error) {
                console.error('deleteClient cannot proceed because of errors');
                this.isDeleteDialogVisible = false; // TODO: show snackbar with error message and refresh counts in case some deletions succeeded
                return;
            }
            */

            const { isDeleted } = await this.$client.data.deleteItemById({ type: 'client', id: this.attr.id });
            this.isDeleteDialogVisible = false;
            if (isDeleted) {
                this.$router.go(-1); // return to last page, since there's no content to show here now
            } else {
                // TODO: show snackbar with error message
            }
        },
        async resetClientUserId() {
            const editedInfo = { ...this.attr.info };
            editedInfo.userId = null;
            const { isEdited } = await this.$client.data.editItemById({ type: 'client', id: this.attr.id }, editedInfo);
            this.isResetClientUserIdDialogVisible = false;
            if (isEdited) {
                // TODO: show snackbar with success message and refresh
            } else {
                // TODO: show snackbar with error message
            }
        },
    },

    async mounted() {
        // get count of realms and account users
        // const { content: { accountCount, userPublicKeyCount } } = await this.$client.data.report({ id: 'user-related-count-by-userId', userId: this.attr.id });
        // this.accountCount = accountCount;
        // this.userPublicKeyCount = userPublicKeyCount;
    },
};
</script>
