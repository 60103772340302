<template>
    <v-col cols="12">
        <v-row>
            <h2>owner id: {{ linkOwnerUserId }}</h2>
        </v-row>
        <v-row>
            <p class="subtitle">{{ id }}</p>
        </v-row>
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider class="mx-5 mb-10"></v-divider>
        <v-row>
            <v-dialog
                v-model="isDeleteDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" outlined>Delete</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Delete User Public Key
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is not reversible via this administration site.
                    </v-card-text>
                    <v-card-text>
                    The authenticator may register the same public key again.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="deletePublicKey">Delete</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
        </v-row>
    </v-col>
</template>

<script>
export default {
    data() {
        return {
            isDeleteDialogVisible: false,
        };
    },

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        linkOwnerUserId() {
            if (this.attr.content.linkOwnerUserId) {
                return this.attr.content.linkOwnerUserId;
            }
            return '';
        },
        json() {
            return JSON.stringify(this.attr.content);
        },
    },

    methods: {
        async deletePublicKey() {
            const { isDeleted } = await this.$client.data.deleteItemById({ type: 'user-publickey', id: this.attr.id });
            this.isDeleteDialogVisible = false;
            if (isDeleted) {
                this.$router.go(-1); // return to last page, since there's no content to show here now
            } else {
                // TODO: show snackbar with error message
            }
        },
    },

};
</script>
