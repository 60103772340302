<template>
    <div>
        <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-row>
            <h2>{{ name }}</h2>
        </v-row>
        <v-row>
            <p class="subtitle">User id: {{ userId }}</p>
            <p class="subtitle">Link id: {{ id }}</p>
        </v-row>
        <v-row>
            <p class="subtitle">Permissions</p>
        </v-row>
        <v-row>
            <v-checkbox v-model="isPermitEdit">
                <template v-slot:label>Edit</template>
            </v-checkbox>
        </v-row>
        <!--
        <div>
            <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
        </div>
        -->
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-row>
            <v-list dense>
                <v-list-item v-if="userId">
                    <router-link :to="{ path:'/report/view', query: {id:'user', userId } }">User</router-link>
                </v-list-item>
                <!--
                <v-list-item v-if="userPublicKeyId">
                    <router-link :to="{ path:'/report/view', query: {id:'user-publickey', userPublicKeyId: userPublicKeyId } }">Public key</router-link>
                </v-list-item>
                -->
            </v-list>
        </v-row>
    </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider class="mx-5 mb-10"></v-divider>
        <v-row>
            <v-dialog
                v-model="isDeleteDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" outlined>Unlink</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Unlink
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is reversible.
                    </v-card-text>
                    <!--
                    <v-card-text v-if="accountCount">
                    The user will be removed from {{ accountCount }} linked accounts.
                    </v-card-text>
                    <v-card-text v-if="userPublicKeyCount">
                    {{ userPublicKeyCount }} linked public keys will be deleted.
                    </v-card-text>
                    -->

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="deleteLinkAccountUser">Unlink</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDeleteDialogVisible: false,
            // accountCount: null,
            // userPublicKeyCount: null,
            name: null,
        };
    },

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        userId() {
            if (this.attr.user_id) {
                return this.attr.user_id;
            }
            return '';
        },
        json() {
            return JSON.stringify(this.attr.content);
        },
        isPermitEdit: {
            get() {
                return this.attr.content.permit && this.attr.content.permit.includes('edit');
            },
            set(value) {
                console.log(`isPermitEdit.set called with value ${value}`);
                this.permit('edit', value);
            },
        },
    },

    methods: {
        async deleteLinkAccountUser() {
            // delete linked user public keys
            // let error = false;
            const { isDeleted } = await this.$client.data.deleteItemById({ type: 'link-account-user', id: this.attr.id });
            this.isDeleteDialogVisible = false;
            if (isDeleted) {
                this.$router.go(-1); // return to last page, since there's no content to show here now
            } else {
                // TODO: show snackbar with error message
            }
        },
        async permit(name, value) {
            // ensure there is only one mention of each permission
            const permitMap = {};
            if (this.attr.content.permit) {
                for (let i = 0; i < this.attr.content.permit.length; i += 1) {
                    const key = this.attr.content.permit[i];
                    permitMap[key] = true;
                }
            }
            // add or change the specified permission
            permitMap[name] = value;
            // make a list of enabled permissions
            const edited = Object.keys(permitMap).filter(key => permitMap[key]);
            // new permission list
            console.log(`edited permission list: ${JSON.stringify(edited)}`);
            const { isEdited } = await this.$client.linkAccountUser.edit(this.attr.id, { permit: edited });
            console.log(`editIsCurrent: isEdited ${isEdited}`);
        },
    },

    async mounted() {
        // get user information
        const userInfo = await this.$client.data.report({ id: 'userById', userId: this.userId });
        if (userInfo && userInfo.content) {
            console.log(`loaded user info ${JSON.stringify(userInfo.content)}`);
            this.name = userInfo.content.content.name;
        }
    },
};
</script>
