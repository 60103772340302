<template>
    <div>
        <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
            <h2>Name: {{ name }}</h2>
            <p class="subtitle">{{ id }}</p>
            <p>Verified email ({{ verifiedEmail.length }})</p>
            <v-list>
                <v-list-item v-for="item in verifiedEmail" v-bind:key="item">{{ item }}</v-list-item>
            </v-list>
            <p>Tags ({{ tagList.length }})</p>
            <v-chip v-for="tag in tagList" v-bind:key="tag">{{ tag }}</v-chip>
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-row>
            <v-list dense>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: {id:'user-email-list-by-userid', userId: id } }">Email Addresses</router-link>
                    <span class="ml-2" v-if="userEmailCount !== null">({{ userEmailCount }})</span>
                </v-list-item>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: {id:'account-list-by-userid', userId: id } }">Accounts</router-link>
                    <span class="ml-2" v-if="accountCount !== null">({{ accountCount }})</span>
                </v-list-item>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: {id:'user-publickey-list-by-userid', userId: id } }">Public keys</router-link>
                    <span class="ml-2" v-if="userPublicKeyCount !== null">({{ userPublicKeyCount }})</span>
                </v-list-item>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: {id:'client-list-by-userid', userId: id } }">Clients</router-link>
                    <span class="ml-2" v-if="clientCount !== null">({{ clientCount }})</span>
                </v-list-item>
            </v-list>
        </v-row>
    </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider class="mx-5 mb-10"></v-divider>
        <v-row>
            <v-dialog
                v-model="isDeleteDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" outlined>Delete</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Delete User
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is not reversible.
                    </v-card-text>
                    <v-card-text v-if="accountCount">
                    The user will be removed from {{ accountCount }} linked accounts.
                    </v-card-text>
                    <v-card-text v-if="userPublicKeyCount">
                    {{ userPublicKeyCount }} linked public keys will be deleted.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="deleteUser">Delete</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        isDeleteDialogVisible: false,
        accountCount: null,
        userPublicKeyCount: null,
        userEmailCount: null,
        clientCount: null,
    }),

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        name() {
            if (this.attr.content.name) {
                return this.attr.content.name;
            }
            return '';
        },
        // deprecated -- user emails now available via separate query
        verifiedEmail() {
            if (this.attr.content.verified && this.attr.content.verified.email) {
                return this.attr.content.verified.email;
            }
            return [];
        },
        tagList() {
            if (Array.isArray(this.attr.content.tag)) {
                return this.attr.content.tag;
            }
            return [];
        },
        json() {
            return JSON.stringify(this.attr.content);
        },
    },

    methods: {
        async deleteUser() {
            // delete user public keys
            let error = false;
            if (this.userPublicKeyCount) {
                const { data } = await this.$client.data.report({ id: 'user-publickey-list-by-userid', userId: this.attr.id });
                const userPublicKeyIdList = data.map(item => item.id);
                console.log(`deleteUser: found ${data.length} related public keys: ${JSON.stringify(userPublicKeyIdList)}`);
                const userPublicKeyDeletePromiseList = userPublicKeyIdList.map(userPublicKeyId => this.$client.data.deleteItemById({ type: 'user-publickey', id: userPublicKeyId }));
                const isDeletedList = await Promise.all(userPublicKeyDeletePromiseList);
                console.log(`deleteUser: delete results ${JSON.stringify(isDeletedList)}`);
                const isDeleteError = isDeletedList.reduce((prev, current) => prev || !current, false);
                console.log(`deleteUser: error: ${isDeleteError}`);
                error = error || isDeleteError;
            }

            if (this.userEmailCount) {
                const { data } = await this.$client.data.report({ id: 'user-email-list-by-userid', userId: this.attr.id });
                const userEmailIdList = data.map(item => item.id);
                console.log(`deleteUser: found ${data.length} related emails: ${JSON.stringify(userEmailIdList)}`);
                const userEmailDeletePromiseList = userEmailIdList.map(userEmailId => this.$client.data.deleteItemById({ type: 'user-email', id: userEmailId }));
                const isDeletedList = await Promise.all(userEmailDeletePromiseList);
                console.log(`deleteUser: delete results ${JSON.stringify(isDeletedList)}`);
                const isDeleteError = isDeletedList.reduce((prev, current) => prev || !current, false);
                console.log(`deleteUser: error: ${isDeleteError}`);
                error = error || isDeleteError;
            }

            // TODO: delete link-realm-user entries ; also get the public key ids from those and delete them

            if (this.accountCount) {
                const { data } = await this.$client.data.report({ id: 'account-list-by-userid', userId: this.attr.id });
                const linkAccountUserList = data.map(item => item.link_id);
                console.log(`deleteUser: found ${data.length} related account-user links: ${JSON.stringify(linkAccountUserList)}`);
                const linkAccountUserDeletePromiseList = linkAccountUserList.map(linkAccountUserId => this.$client.data.deleteItemById({ type: 'link-account-user', id: linkAccountUserId }));
                const isDeletedList = await Promise.all(linkAccountUserDeletePromiseList);
                console.log(`deleteUser: delete results ${JSON.stringify(isDeletedList)}`);
                const isDeleteError = isDeletedList.reduce((prev, current) => prev || !current, false);
                console.log(`deleteUser: error: ${isDeleteError}`);
                error = error || isDeleteError;
            }

            if (this.clientCount) {
                // TODO: but to delete a client, we also need to delete all the client's related records... and this is already in ClientViewItem... so we need to be able to import a client management object and issue a delete command to it
                const { data } = await this.$client.data.report({ id: 'client-list-by-userid', userId: this.attr.id });
                const clientList = data.map(item => item.link_id);
                console.log(`deleteUser: found ${data.length} related clients: ${JSON.stringify(clientList)}`);
                const clientDeletePromiseList = clientList.map(clientId => this.$client.data.deleteItemById({ type: 'client', id: clientId }));
                const isDeletedList = await Promise.all(clientDeletePromiseList);
                console.log(`deleteUser: delete results ${JSON.stringify(isDeletedList)}`);
                const isDeleteError = isDeletedList.reduce((prev, current) => prev || !current, false);
                console.log(`deleteUser: error: ${isDeleteError}`);
                error = error || isDeleteError;
            }

            if (error) {
                console.error('deleteUser cannot proceed because of errors');
                this.isDeleteDialogVisible = false; // TODO: show snackbar with error message and refresh counts in case some deletions succeeded
                return;
            }

            const { isDeleted } = await this.$client.data.deleteItemById({ type: 'user', id: this.attr.id });
            this.isDeleteDialogVisible = false;
            if (isDeleted) {
                this.$router.go(-1); // return to last page, since there's no content to show here now
            } else {
                // TODO: show snackbar with error message
            }
        },
    },

    async mounted() {
        // get count of realms and account users
        const {
            content: {
                accountCount, userPublicKeyCount, userEmailCount, clientCount,
            },
        } = await this.$client.data.report({
            id: 'user-related-count-by-userId', userId: this.attr.id,
        });
        this.accountCount = accountCount;
        this.userPublicKeyCount = userPublicKeyCount;
        this.userEmailCount = userEmailCount;
        this.clientCount = clientCount;
    },
};
</script>
