<template>
    <div>
        <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-row>
            <h2>Realm name: {{ label }} ({{domain}})</h2>
        </v-row>
        <v-row>
            <p class="subtitle">{{ id }}</p>
        </v-row>
        <v-row>
            <p class="subtitle">Realm ID: {{ realmId }}</p>
        </v-row>
        <!--
        <v-row>
            <h2>Verified email</h2>
        </v-row>
        <v-row>
            <v-list>
                <v-list-item v-for="item in verifiedEmail" v-bind:key="item">{{ item }}</v-list-item>
            </v-list>
        </v-row>
        -->
        <!--
    <div>
        <span><router-link :to="{path: '/report/view', query: { id: 'user', userId: id }}">{{ name }}</router-link></span>
    </div>
    -->
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-row>
            <v-list dense>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: { id:'account', accountId } }">Account</router-link>
                </v-list-item>
                <v-list-item>
                    <router-link :to="{ path:'/report/view', query: { id:'link-realm-user-list-by-realmid', realmId } }">Realm users</router-link>
                </v-list-item>
            </v-list>
        </v-row>
    </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>JSON</v-expansion-panel-header>
                    <v-expansion-panel-content>
                    {{ json }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-divider class="mx-5 mb-10"></v-divider>
        <v-row>
            <v-dialog
                v-model="isDeleteDialogVisible"
                width="500"
                >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="red" outlined>Delete</v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-3 red--text" primary-title>
                        Delete Realm
                    </v-card-title>
                    <v-divider class="mx-5 mb-10"></v-divider>
                    <v-card-text>
                    This action is not reversible.
                    </v-card-text>
                    <v-card-text>
                    New realms are assigned a randomly generated identifier and all realm users would need to register again with the new realm.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="white--text" @click="deleteRealm">Delete</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
        </v-row>

</div>
</template>

<script>
export default {
    data() {
        return {
            isDeleteDialogVisible: false,
        };
    },

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        label() {
            if (this.attr.content.label) {
                return this.attr.content.label;
            }
            return '';
        },
        domain() {
            if (this.attr.content.domain) {
                return this.attr.content.domain;
            }
            return '';
        },
        realmId() {
            return this.attr.realm_id;
        },
        accountId() {
            return this.attr.account_id;
        },
        /*
        verifiedEmail() {
            if (this.attr.content.verified && this.attr.content.verified.email) {
                return this.attr.content.verified.email;
            }
            return [];
        },
        */
        json() {
            return JSON.stringify(this.attr.content);
        },
    },

    methods: {
        async deleteRealm() {
            const { isDeleted } = await this.$client.data.deleteItemById({ type: 'realm', id: this.attr.id });
            this.isDeleteDialogVisible = false;
            if (isDeleted) {
                this.$router.go(-1); // return to last page, since there's no content to show here now
            } else {
                // TODO: show snackbar with error message
            }
        },
    },
};
</script>
